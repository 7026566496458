const reactToastifyConfig = {
	position: 'top-center',
	autoClose: 3000,
	hideProgressBar: false,
	newestOnTop: false,
	closeOnClick: true,
	rtl: false,
	pauseOnFocusLoss: true,
	draggable: false,
	pauseOnHover: true,
	limit: 4,
};

export default reactToastifyConfig;
