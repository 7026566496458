import currency from '../../config/currency.json';
const currencyCodes = Object.keys(currency) || [];

/**
 * Dependencies:
 * Array providers -> config > apiProviders
 * function t -> translate pages/settings
 * Obj initialData -> default settings
 *
 */

export default function settingsReducer(state, action) {
	const { type, payload, error, success, dependencies = {} } = action;
	const { providers = [], t, initialData } = dependencies || {};

	/**
	 * Handle success middleware
	 * Removes keys that weren't in the default state
	 * @param {*} newState
	 * @returns
	 */
	const onSuccess = (newState) => {
		success?.();
		//Get keys from default state and dont allow any outside keys
		const cleanNewState = Object.keys(initialData).reduce((acc, key) => {
			if (key in newState) {
				return { ...acc, [key]: newState[key] };
			} else {
				return { ...acc, [key]: initialData[key] };
			}
		}, {});
		//Add timestamp
		cleanNewState.updatedAt = Date.now();
		return cleanNewState;
	};

	const onError = (code) => {
		error?.(code ? t(code) : '');
		return state;
	};

	switch (type) {
		/**
		 * Save settings
		 * requires payload with all settings
		 */
		case 'SaveSettings': {
			//Error Checking
			if (!payload || 'currencies' in payload === false || 'apiProvider' in payload === false) {
				return onError('error.InvalidData');
			}
			if (!state.setupComplete && !currencyCodes.includes(payload?.defaultCurrency)) {
				return onError('error.UnsupportedCurrency');
			}

			//Initial Setup
			if (state.setupComplete > 0 && 'defaultCurrency' in payload) {
				if (payload.defaultCurrency !== state.defaultCurrency) {
					return onError('error.DefaultCurrencyChange');
				}
			}

			//Get payload currencies
			const currencies = Array.isArray(payload?.currencies) ? payload.currencies : [];
			const favoriteCurrencies = Array.isArray(payload?.favoriteCurrencies) ? payload.favoriteCurrencies : [];

			//Filter currencies and remove default currency if exists
			const newState = {
				...state,
				...payload,
				currencies: currencies.filter((c) => c !== payload.defaultCurrency),
				favoriteCurrencies: favoriteCurrencies.filter((c) => c !== payload.defaultCurrency),
			};

			//Set setup complete if this is initial setup
			if (!state.setupComplete) newState.setupComplete = Date.now();

			//Sort
			newState.currencies.sort();
			newState.favoriteCurrencies.sort();

			return onSuccess(newState);
		}

		/**
		 * Check if currently active api is still available in config.
		 * Dispatch this action from settings page
		 */
		case 'ApiHealthCheck': {
			//Check if currently selected api is still available in config
			const apiId = state.apiProvider;
			if (!apiId) return state;
			const provider = providers.find((item) => item.id === apiId);

			if (!provider) {
				//Selected api provider is no longer available. Remove it
				return onSuccess({ ...state, apiProvider: '', apiKey: '' });
			}
			return state;
		}

		/**
		 * Leave demo mode by changing isDemo to false
		 */
		case 'LeaveDemo': {
			if (state?.isDemo !== true) {
				return onError();
			}
			return onSuccess({ ...state, isDemo: false });
		}

		/**
		 * Invalid Dispatch
		 */
		default: {
			throw new Error('Invalid Dispatch Type @ SettingsReducer');
		}
	}
} //EOF
